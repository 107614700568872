import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const LoginAsParentPopup = ({ openLoginAsParentDialog, handleCloseLoginAsParentDialog, handleConfirmLoginAsParent }) => {
    return (
        <Dialog open={openLoginAsParentDialog} onClose={handleCloseLoginAsParentDialog}>
            <DialogTitle>
                <span className="login-as-parent-title">Parent Access Required</span>
                <span className="self-learning-w-close" onClick={handleCloseLoginAsParentDialog}>
                    <CloseIcon />
                </span>
            </DialogTitle>
            <DialogContent>
                <p className="login-as-parent-content">A parent account is required to make a purchase. Clicking 'Login as Parent' will log you out of the current session and redirect you to the parent login page.</p>


                <div className="d-flex align-items-center justify-content-end">
                    <button onClick={handleCloseLoginAsParentDialog} className="login-as-parent-cancel-btn">
                        Cancel
                    </button>
                    <button onClick={handleConfirmLoginAsParent} className="ms-3 login-as-parent-btn">
                        Login as Parent
                    </button>
                </div>
            </DialogContent>
           
        </Dialog>
    );
};


export default LoginAsParentPopup;
