import React, { useEffect } from "react";
//import Header from '../../components/header/header';
import Header from "./header";
import "./privacy-policy.css";
import Footer from "../../components/footer/footer";
import conditional_configs from "../../config/conditional_config";

export default function PrivacyPolicy() {
  document.title = "Meeku | Privacy Policy";

  return (
    <div>
      <Header screen={"home"} />
      <div class="container container-privacy-policy">
        <div className="body">
          <h1 className="header-privacy-policy">MEEKU LLC PRIVACY POLICY</h1>
          <p className="content-privacy-policy mb-4">
            Welcome to Meeku LLC! Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your personal information when you use our platform. We are committed to safeguarding your data and ensuring that your experience with us is secure and transparent. By using our services, you agree to the practices described in this policy. If you have any questions or concerns about how we handle your information, please contact us.
          </p>

          <section className="content-privacy-policy">
            <h3 className="underline font-weight-bold-privacy mb-2">1. INFORMATION WE COLLECT</h3>

            <p class="content-privacy-policy">At Meeku LLC, we collect the following types of information to provide and improve our services:</p>
            <p class="content-privacy-policy">Personal Information: When you create an account or use our platform, we collect personal details such as your child’s name, date of birth, email address, and any other information you provide.</p>
            <p class="content-privacy-policy">Usage Data: We gather data about how you use our platform, including your interactions, preferences, and the features you use. This helps us understand your needs and enhance your experience.</p>
            <p class="content-privacy-policy">Device Information: We collect information about the device you use to access our platform, such as your IP address, browser type, operating system, and device identifiers.</p>
            <p class="content-privacy-policy">Log Data: Our servers automatically record certain information about your visit to our platform, including the time and date of your visit, the pages you viewed, and any errors that occurred.</p>
            <p class="content-privacy-policy">Cookies and Tracking Technologies: We use cookies and similar technologies to track your activity on our platform and remember your preferences. This helps us provide a more personalized experience.</p>


          </section>

          <section className="content-privacy-policy">
            <h3 className="underline font-weight-bold-privacy mb-2">2. HOW WE USE INFORMATION</h3>
            <p class="content-privacy-policy">We use the information we collect to:</p>
            <p class="content-privacy-policy">Provide and Improve Our Services: Your information helps us operate, maintain, and enhance the Meeku platform, ensuring a safe and enjoyable experience for all users.</p>
            <p class="content-privacy-policy">Personalize Your Experience: We use your data to tailor the content and resources available on our platform to meet your specific needs and interests.</p>

            <p class="content-privacy-policy">Communicate with You: We may use your contact details to send important updates, respond to inquiries, and provide customer support.</p>
            <p class="content-privacy-policy">Ensure Safety and Security: Information is used to monitor activities on the platform, prevent unauthorized access, and safeguard our users, especially children.</p>
            <p class="content-privacy-policy">Comply with Legal Requirements: We process your data in accordance with applicable laws and regulations, such as COPPA, to ensure we meet our legal obligations.</p>
          </section>

          <section className="content-privacy-policy">
            <h3 className="underline font-weight-bold-privacy mb-2">3. SHARING YOUR INFORMATION</h3>
            <p class="content-privacy-policy">We value your privacy and are committed to protecting your personal information. Meeku LLC does not sell or rent your personal information to third parties. We only share your information in the following circumstances:</p>
            <p class="content-privacy-policy">With Service Providers: We may share your information with trusted third-party service providers who assist us in operating our platform, providing services, or conducting our business. These providers are bound by strict confidentiality agreements and are only permitted to use your information as necessary to perform their services.</p>
            <p class="content-privacy-policy">Legal Obligations: We may disclose your information if required to do so by law or in response to valid legal requests from government authorities, such as in connection with a court order or subpoena.</p>
            <p class="content-privacy-policy">Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the new owners as part of the transaction. We will notify you if such a transfer occurs and if your information becomes subject to a different privacy policy.</p>
            <p class="content-privacy-policy">Protection of Rights: We may share your information when we believe it is necessary to enforce our terms and conditions, protect our rights, privacy, safety, or property, or prevent fraud or other illegal activities.</p>

          </section>

          <section className="content-privacy-policy">
            <h3 className="underline font-weight-bold-privacy mb-2">4. DATA SECURITY</h3>
            <p class="content-privacy-policy">We take the security of your personal information very seriously. To protect your data from unauthorized access, alteration, or disclosure, we implement industry-standard security measures, including encryption, secure storage, and regular security audits. Access to personal data is restricted to authorized personnel who require it to perform their job functions.</p>
            <p class="content-privacy-policy">While we strive to use commercially acceptable means to protect your personal information, please note that no method of transmission over the Internet or method of electronic storage is 100% secure. Therefore, we cannot guarantee its absolute security. If you have any concerns about the security of your data, please contact us.</p>

          </section>

          <section className="content-privacy-policy">
            <h3 className="underline font-weight-bold-privacy mb-2">5. PARENTAL CONSENT AND CONTROLS</h3>
            <p class="content-privacy-policy">We take your child’s privacy seriously and require verifiable parental consent before collecting, using, or disclosing any personal information from children under the age of 13.</p>
            <p class="content-privacy-policy">Obtaining Consent: Before your child can access certain features of our platform, we will seek your consent as their parent or legal guardian. This process may involve providing a signed consent form, using a credit card or other payment method, or through other appropriate methods in line with the principles of the Children’s Online Privacy Protection Act (COPPA).</p>
            <p class="content-privacy-policy">Managing Your Child's Information: As a parent, you have full control over your child’s account and personal information. You can:</p>
            <ul>
              <li>Review Information: Access and review the personal information we have collected from your child.</li>
              <li>Update or Correct Information: Modify your child’s personal information to ensure it is accurate and up-to-date.</li>
              <li>Delete Information: Request the deletion of your child’s personal information at any time.</li>
              <li>Withdraw Consent: You may withdraw your consent at any time, and we will delete your child’s account and any associated information.</li>
            </ul>

            <p class="content-privacy-policy">To exercise any of these rights please contact us. We are committed to responding to your requests promptly to ensure your child’s privacy and safety.</p>

          </section>

          <section className="content-privacy-policy">
            <h3 className="underline font-weight-bold-privacy mb-2">6. YOUR RIGHTS AND CHOICES</h3>
            <p class="content-privacy-policy">At Meeku LLC, we respect your rights and provide you with the following choices regarding your personal information:</p>
            <p class="content-privacy-policy">Access and Update: You have the right to access and update your personal information at any time. If you notice that your information is incorrect or incomplete, you can update it through your account settings or by contacting us directly.</p>
            <p class="content-privacy-policy">Data Portability: Upon request, we will provide you with a copy of your personal information in a structured, commonly used, and machine-readable format.</p>
            <p class="content-privacy-policy">Deletion: You can request the deletion of your personal information from our records. We will comply with your request unless we have a legitimate reason or legal obligation to retain certain data.</p>
            <p class="content-privacy-policy">Withdraw Consent: If we rely on your consent to process your personal information, you have the right to withdraw that consent at any time. This may affect your ability to use certain features of our platform.</p>
            <p class="content-privacy-policy">Object to Processing: You have the right to object to the processing of your personal information if you believe it is being used unlawfully or in a manner inconsistent with your expectations.</p>
            <p class="content-privacy-policy">Restrict Processing: In certain circumstances, you may request that we limit the processing of your personal information. This means we will store your data but not use it further.</p>
            <p class="content-privacy-policy">Opt-Out of Marketing Communications: You can opt out of receiving promotional emails or messages from us by following the unsubscribe instructions in any communication or by adjusting your account settings.</p>

          </section>

          <section className="content-privacy-policy">
            <h3 className="underline font-weight-bold-privacy mb-2">7. CHANGES TO THIS PRIVACY POLICY:</h3>
            <p class="content-privacy-policy">We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes and provide you with the opportunity to review them before they take effect.</p>

          </section>

          <section className="content-privacy-policy">
            <h3 className="underline font-weight-bold-privacy mb-2">8. CONTACT US:</h3>
            <p class="content-privacy-policy">If you have any questions or concerns about this Privacy Policy or how we handle your child’s information, please contact us.</p>

          </section>


        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
