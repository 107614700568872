
import assets from './assets';



// TEST
const white_board_instructions = {
    whiteboardList: [
        {
            id: 1,
            name: "Amazon Delivery System",
            instructions: true,
            steps: [
                { step: "Create a warehouse", description: "Your first task is to find for a warehouse which you can utilize for making it as “Amazon Delivery System”", stepImages: assets.amazon_img.amazon_s_1 },
                { step: "Learn the warehouse operations", description: "make a list of operations that are held in the amazon delivery system.", stepImages: assets.amazon_img.amazon_s_2 },
                { step: "Robots installation", description: "On creation of warehouse your work is to find different robots for operating within the warehouse.", stepImages: assets.amazon_img.amazon_s_3 },
                { step: "Assign robots with specific work", description: "Robots with specific features performs specific work, you will need to assign robots with correct work accordingly.", stepImages: assets.amazon_img.amazon_s_4 },
                { step: "Take orders and execute them.", description: "The system is created to make reach [products from amazon system, one you get the work completed with the robots.", stepImages: assets.amazon_img.amazon_s_5 }
            ]
        },
        {
            id: 2,
            name: "SuDoKu",
            instructions: true,
            steps: [
                { step: "Select your grid size", description: "The first step is to choose the grid size of your choice.", stepImages: assets.sudoku_img.sudoku_s_1 },
                { step: "Choose robo components", description: "Here, instead of numbers, we use robo components to place in the grid for Sudoku.", stepImages: assets.sudoku_img.sudoku_s_2 },
                { step: "Follow the rule", description: "Place the robo components according to the prescribed rule.", stepImages: assets.sudoku_img.sudoku_s_3 },
                { step: "Fill and backtrack", description: "To ensure you are solving the challenge correctly, frequently check if the conditions are being followed.", stepImages: assets.sudoku_img.sudoku_s_4 },
                { step: "Complete the puzzle", description: "After following all the previous steps, you will have a solved Robo Sudoku puzzle.", stepImages: assets.sudoku_img.sudoku_s_5 }
            ]
        },
        {
            id: 3,
            name: "Maze",
            instructions: true,
            steps: [
                { step: "Create A Grid", description: "Design a grid of your choice to serve as the maze structure", stepImages: assets.maze_img.maze_s_1 },
                { step: "Create Paths", description: "Design multiple paths within the grid leading to the goal. Add alternateroutes to make the maze more engaging.", stepImages: assets.maze_img.maze_s_2 },
                { step: "Add Features",  description: "Include collectible coins or rewards along the way for extra points. +10 for each coin collected", stepImages: assets.maze_img.maze_s_3 },  
                { step: "Obstacles On The Way", description: "Place obstacles to challenge navigation. Deduct points if a player encounters an obstacle. -5 for obstacle passed", stepImages: assets.maze_img.maze_s_4 }, 
                { step: "Write The Algorithm", description: "In order to reach goal you will need to write algorithms. Use simple code blocks like ,", stepImages: assets.maze_img.maze_s_5 },
                { step: "Reach The Goal", description: "Navigate through the best available route to reach the goal efficiently.", stepImages: assets.maze_img.maze_s_2 }
            ]
        },
        {
            id: 4,
            name: "Line Control Robot",
            instructions: true,
            steps: [
                { step: "Construct a city layout", description: "First, obtain the blueprint of the city and identify all available routes.", stepImages: assets.line_control.line_s_1 },
                { step: "Place robots appropriately", description: "Position different robots across the layout based on their functionality.", stepImages: assets.line_control.line_s_2 },
                { step: "Program robot to sequentially pass the letters", description: "Ensure the robots are programmed to pass the letter to the next robot in the correct sequence.", stepImages: assets.line_control.line_s_3 },
                { step: "Learn to avoid the obstacles", description: "Identify potential obstacles and program the robots to navigate through them efficiently.", stepImages: assets.line_control.line_s_4 },
                { step: "Deliver the letter to the doorstep", description: "Finally, ensure the letter reaches the correct recipient, either by direct delivery or by placing it in their home postbox", stepImages: assets.line_control.line_s_5 }
            ]
        },
        {
            id: 5,
            name: "Moon Mission",
            instructions: true,
            steps: [
                { step: "Build Your Satellite!", description: "Exploring the Moon is an exciting adventure! First, you need to build a strong and smart satellite to help with the mission.", stepImages: assets.moon_img.moon_s_1 },
                { step: "Blast Off and Travel Through Earth’s Layers!", description: "To reach space, your rocket must pass through different layers of the sky. As you go higher, your rocket needs to go faster to escape Earth's pull", stepImages: assets.moon_img.moon_s_2 },
                { step: "Enter Space and Reach the Moon!", description: "Once you leave Earth's atmosphere, your satellite will travel through space towards the Moon. It must follow the right path and speed to arrive safely.", stepImages: assets.moon_img.moon_s_3 },
                { step: "Land and Release the Rover!", description: "When your satellite reaches the Moon, it will release a rover. This small robot will drive around, take pictures, and collect important information.", stepImages: assets.moon_img.moon_s_4 },
                { step: "Write The Algorithm", description: "In order to reach goal you will need to write algorithms. Use simple code blocks like ,", stepImages: assets.moon_img.moon_s_5 },
                { step: "Explore and Send Data Back to Earth!", description: "The rover will explore the Moon’s surface, studying rocks, soil, and temperature. It will send all the exciting discoveries back to scientists on Earth!", stepImages: assets.moon_img.moon_s_6 }
            ]
        },
        {
            id: 6,
            name: "Soccer",
            instructions: true,
            steps: [
                { step: "Create A Virtual Board", description: "Set up a virtual soccer board with two teams of virtual robots.", stepImages: assets.soccer.soccer_s_1 },
                { step: "Assign Roles and Movement", description: "Each robot has multiple roles like passing, defending, and attacking. Adjust roles and define movement paths for smooth play.", stepImages: assets.soccer.soccer_s_2 },
                { step: "Plan Offense", description: "Program robots to pass in sequence and advance toward the goal. Optimize passing routes for efficiency.", stepImages: assets.soccer.soccer_s_3 },
                { step: "Strengthen Defense", description: "Identify obstacles and defensive threats. Program robots to navigate obstacles and block attacks.", stepImages: assets.soccer.soccer_s_4 },
                { step: "Score Points", description: "Try to gain more points than the opponent to win the game", stepImages: assets.soccer.soccer_s_5 },
                { step: "Write The Algorithm", description: "In order to reach goal you will need to write algorithms .Use simple code blocks like ,", stepImages: assets.soccer.soccer_s_6 }
            ]
        },
        {
            id: 7,
            name: "Restaurant",
            instructions: true,
            steps: [
                { step: "Create a Virtual Restaurant", description: "Design a restaurant layout with all necessary features. Identify available routes for efficient navigation.", stepImages: assets.restaurant.restaurant_s_1 },
                { step: "Divide Work into Departments", description: "Assign different operations such as cooking, serving, and cleaning. Position robots based on their specific tasks,", stepImages: assets.restaurant.restaurant_s_2 },
                { step: "Program Robots for Tasks", description: "Ensure robots follow a proper workflow for seamless operations. Program them to coordinate tasks efficiently.", stepImages: assets.restaurant.restaurant_s_3  },
                { step: "Optimize Navigation and Delivery", description: "Identify potential obstacles and program robots to navigate smoothly. Ensure orders reach the correct recipient efficiently.", stepImages: assets.restaurant.restaurant_s_4 }
            ]
        },
        {
            id: 8,
            name: "Robotronics",
            instructions: true,
            steps: [
                { step: "Sketch Your Robot", description: "There are different types of robot your work is to find the robot that you want to build.", stepImages: assets.robo_mart_s_img.robo_mart_s_1 },
                { step: "Know your budget", description: "In oder to buiuld toyr own robot you will be given with a budget.", stepImages: assets.robo_mart_s_img.robo_mart_s_2 },
                { step: "Robot functionalites", description: "You need to make a list of functionalities that your robot must posses.", stepImages: assets.robo_mart_s_img.robo_mart_s_3 },
                { step: "Buy the parts", description: "After you know your budget and robot functionalities, you need to buy the parts and assemble them.", stepImages: assets.robo_mart_s_img.robo_mart_s_4 }
            ]
        },
        {
            id: 9,
            name: "Shoe Factory",
            instructions: true,
            steps: [
                { step: "Choose your shoe design", description: "Hey! Burst out with creativity. Design your own shoe virtually and bring it to the market.", stepImages: assets.foot_world.foot_world_s_1 },
                { step: "Select a proper factory layout", description: "To manufacture the shoes, you need a well-planned factory layout", stepImages: assets.foot_world.foot_world_s_2 },
                { step: "Divide the work into different departments", description: "Factory work is not a oneperson job. Divide the tasks among different departments and collaborate with your team.", stepImages: assets.foot_world.foot_world_s_3 },
                { step: "Assign robots specific job", description: "Use different robots in various areas to improve production and speed up the delivery process.", stepImages: assets.foot_world.foot_world_s_4 },
                { step: "Make your shoes available for all!", description: "Your shoes are now ready to hit the market!", stepImages: assets.foot_world.foot_world_s_5 }
            ]
        },

    ]
};



export default white_board_instructions;