import React, { useEffect } from "react";
//import Header from '../../components/header/header';
import Header from "./header";
import "./terms-and-conditions.css";
import url_configs from "../../config/url";
import Footer from "../../components/footer/footer";

export default function TermsAndConditions() {
  document.title = "Meeku | Terms and Conditions";

  return (
    <div>
      <Header screen={"home"} />
      <div className="container container-terms-and-conditions">
        <div className="body">
          <h1 className="header-terms-and-conditions">MEEKU LLC TERMS AND CONDITIONS</h1>

          <p className="content-terms-and-conditions">Welcome to Meeku LLC! We’re excited to have you on our platform, where kids can learn, play, and connect in a safe and engaging environment. These Terms and Conditions (“Terms”) govern your use of our services and website. By accessing or using Meeku LLC’s platform, you agree to comply with and be bound by these Terms.</p>
          <p className="content-terms-and-conditions">Please read these Terms carefully. They outline your rights and responsibilities, and how we handle your information. If you don’t agree with any part of these Terms, please do not use our platform.</p>
          <p className="content-terms-and-conditions">We may update these Terms from time to time to reflect changes in our services or legal requirements. We will notify you of any significant changes, and your continued use of the platform constitutes acceptance of the revised Terms.</p>
          <p className="content-terms-and-conditions">If you have any questions or need further clarification about these Terms, feel free to contact us.</p>


          {/* 1. ACCEPTANCE OF TERMS */}

          <h4 className="underline font-weight-bold-privacy">1. ACCEPTANCE OF TERMS</h4>

          <p className="content-terms-and-conditions">By accessing and using the Meeku LLC platform, you agree to be bound by these Terms and Conditions, as well as any additional guidelines or policies that may be posted on the platform from time to time. If you do not agree to these terms, you must not use the platform.</p>
          <p className="content-terms-and-conditions">Your continued use of the platform constitutes your acceptance of any changes or updates to these Terms and Conditions. We reserve the right to modify these terms at any time, and we will notify you of significant changes. It is your responsibility to review the Terms and Conditions periodically for any updates.</p>
          <p className="content-terms-and-conditions">By creating an account or using our services, you affirm that you are of legal age to enter into this agreement or that you have obtained parental consent if you are under the legal age.</p>


          {/* 2. DEFINITIONS */}
          <h4 className="underline font-weight-bold-privacy">2. DEFINITIONS</h4>


          <p className="content-terms-and-conditions">"Meeku LLC" Refers to Meeku LLC, the company providing the social learning platform for children, including its subsidiaries, affiliates, and related entities.</p>
          <p className="content-terms-and-conditions">"Platform" Refers to the online social learning platform operated by Meeku LLC, including its website, mobile applications, and any other associated services and features.</p>
          <p className="content-terms-and-conditions">"User" Refers to any individual or entity that accesses or uses the Platform, including parents, guardians, and children.</p>
          <p className="content-terms-and-conditions">"Child" Refers to an individual under the age of 13 who uses the Platform, subject to parental consent as required by applicable laws.</p>
          <p className="content-terms-and-conditions">"Parent" or "Guardian" Refers to an individual who has legal responsibility for a child and provides parental consent for the child’s use of the Platform.</p>
          <p className="content-terms-and-conditions">"Account" Refers to the user profile created on the Platform, which includes registration details, user settings, and other personal information.</p>
          <p className="content-terms-and-conditions">"Content" Refers to all text, images, video, audio, and other materials provided, posted, or shared by Users on the Platform</p>
          <p className="content-terms-and-conditions">"Services" Refers to the features and functionalities offered by the Platform, including but not limited to educational tools, interactive activities, and communication functions.</p>
          <p className="content-terms-and-conditions">"Terms" Refers to these Terms and Conditions governing the use of the Platform, including any updates or modifications made to them.</p>
          <p className="content-terms-and-conditions">"Privacy Policy" Refers to the document outlining how Meeku LLC collects, uses, and protects User information, which is incorporated by reference into these Terms.</p>
          <p className="content-terms-and-conditions">"Kids Policy" Refers to the document specifically outlining the privacy and safety measures for children using the Platform, which is incorporated by reference into these Terms.</p>
          <p className="content-terms-and-conditions">"Subscription" Refers to the payment-based access to certain features or services on the Platform, as detailed in the Payment and Subscription section of these Terms.</p>
          <p className="content-terms-and-conditions">"Third Party" Refers to any person or entity other than Meeku LLC or the User, including service providers, partners, and advertisers.</p>



          {/* 3. USE OF THE PLATFORM */}


          <h4 className="underline font-weight-bold-privacy">3. USE OF THE PLATFORM</h4>

          <p className="content-terms-and-conditions">Platform Access: You may access and use Meeku LLC’s platform only for lawful purposes and in accordance with these Terms and Conditions. Unauthorized or improper use of the platform is strictly prohibited.</p>
          <p className="content-terms-and-conditions">User Conduct: You agree to use the platform responsibly and respectfully. You will not engage in any of the following prohibited activities:</p>

          <ul>
            <li>Uploading or sharing content that is illegal, harmful, threatening, abusive, or discriminatory.</li>
            <li>Harassing, bullying, or threatening other users.</li>
            <li>Impersonating any person or entity or misrepresenting your affiliation with any person or entity.</li>
            <li>Using the platform for any fraudulent or malicious purposes.</li>
          </ul>






          {/* 4. ACCOUNT RESPONSIBILITIES */}

          <h4 className="underline font-weight-bold-privacy">4. ACCOUNT RESPONSIBILITIES</h4>

          <p className="content-terms-and-conditions">Account Security: You are responsible for maintaining the confidentiality of your account credentials, including your username and password. You agree to notify Meeku LLC immediately of any unauthorized use or breach of your account. Meeku LLC is not liable for any loss or damage arising from your failure to protect your account information.</p>
          <p className="content-terms-and-conditions">Accurate Information: You agree to provide accurate, current, and complete information when creating or updating your account. You are responsible for updating your account details to ensure they remain accurate and current.</p>
          <p className="content-terms-and-conditions">Account Use: You are responsible for all activities that occur under your account. You agree to use the platform only for lawful purposes and in accordance with these Terms and Conditions. You must not use your account to engage in any fraudulent, illegal, or harmful activities.</p>
          <p className="content-terms-and-conditions">Account Access: You must not share your account credentials with others or allow anyone else to access your account. If you suspect any unauthorized use of your account, you should change your password immediately and contact Meeku LLC for further assistance.</p>
          <p className="content-terms-and-conditions">Account Termination: Meeku LLC reserves the right to suspend or terminate your account if we determine that you have violated these Terms and Conditions or if we believe your account poses a risk to the platform or its users. You may also request the termination of your account at any time, subject to our policies and procedures.</p>




          {/* 5. PAYMENT AND SUBSCRIPTION */}

          <h3 className="underline font-weight-bold-privacy">5. PAYMENT AND SUBSCRIPTION</h3>


          <p className="content-terms-and-conditions">Subscription Plans: Meeku LLC offers various subscription plans for access to premium features on our platform. Details of the available plans, including pricing and features, are provided on our website or application.</p>
          <p className="content-terms-and-conditions">Payment: Subscription fees are billed on a monthly, quarterly, or annual basis, depending on the plan selected at the time of purchase. Payments are processed through a secure payment gateway. By providing payment information, you authorize us to charge the applicable fees to your chosen payment method.</p>
          <p className="content-terms-and-conditions">Renewal: Your subscription will automatically renew at the end of each billing period unless you cancel before the renewal date. You will be charged the applicable subscription fee at the time of renewal.</p>
          <p className="content-terms-and-conditions">Changes to Subscription Plans: Meeku LLC reserves the right to modify subscription plans, pricing, and terms at any time. We will provide notice of any significant changes to your subscription plan or pricing.</p>


          {/* 6. USER CONTENT AND CONDUCT */}

          <h3 className="underline font-weight-bold-privacy">6. USER CONTENT AND CONDUCT</h3>

          <p className="content-terms-and-conditions">User Content: You are solely responsible for any content you create, post, or transmit through our platform, including but not limited to text, images, and videos ("User Content"). By submitting User Content, you grant Meeku LLC a non-exclusive, royalty-free, perpetual, and worldwide license to use, display, reproduce, and distribute such content in connection with our services.</p>
          <p className="content-terms-and-conditions">Acceptable Use: You agree to use the platform only for lawful purposes and in a manner that does not violate any applicable laws or regulations. You must not engage in any activity that disrupts or interferes with the operation of the platform or any other user’s enjoyment.</p>
          <p className="content-terms-and-conditions">Prohibited Conduct: You must not:</p>

          <ul>
            <li>Post, transmit, or distribute any content that is unlawful, harmful, abusive, defamatory, obscene, or offensive.</li>
            <li>Harass, threaten, or intimidate other users.</li>
            <li>Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with any person or entity.</li>
            <li>Upload or transmit any viruses, malware, or other harmful code.</li>
            <li>Engage in any form of unauthorized data collection, scraping, or harvesting.</li>
          </ul>


          <p className="content-terms-and-conditions">Content Moderation: We reserve the right to review, monitor, and remove any User Content that we deem to be in violation of these Terms and Conditions or harmful to the platform or its users, at our sole discretion.</p>
          <p className="content-terms-and-conditions">Reporting Violations: If you encounter any User Content that you believe violates these Terms and Conditions, please report it to us immediately using the contact information provided.</p>
          <p className="content-terms-and-conditions">Enforcement: Violation of these conduct guidelines may result in suspension or termination of your account and/or legal action, as appropriate.</p>



          {/* 7. TERMINATION OF USE */}

          <h3 className="underline font-weight-bold-privacy">7. TERMINATION OF USE</h3>

          <p className="content-terms-and-conditions">Termination by Us: Meeku LLC reserves the right to suspend or terminate your access to the platform at any time, with or without cause, and with or without notice, if we believe you have violated these Terms and Conditions or engaged in any conduct that is detrimental to the platform or its users.</p>
          <p className="content-terms-and-conditions">Termination by You: You may terminate your account at any time by following the account deactivation process provided in your account settings. Upon termination, you will no longer have access to the platform, and your personal information will be handled in accordance with our Privacy Policy.</p>
          <p className="content-terms-and-conditions">Effects of Termination: Upon termination, whether by you or us, you must cease all use of the platform and destroy any materials or content you have obtained from it. Any provisions of these Terms that by their nature should survive termination will remain in effect.</p>
          <p className="content-terms-and-conditions">Appeals: If you believe your account was terminated in error, you may contact us at [contact email/phone number] to appeal the decision. We will review the appeal and respond accordingly.</p>







          {/* 8. DISCLAIMERS AND LIMITATION OF LIABILITY */}


          <h3 className="underline font-weight-bold-privacy">8. DISCLAIMERS AND LIMITATION OF LIABILITY</h3>


          <p className="content-terms-and-conditions">Disclaimers: The Meeku LLC platform is provided on an "as-is" and "as-available" basis. We make no representations or warranties of any kind, express or implied, regarding the operation of the platform or the information, content, materials, or products included on the platform. To the fullest extent permitted by law, we disclaim all warranties, express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
          <p className="content-terms-and-conditions">Limitation of Liability: In no event shall Meeku LLC, its affiliates, officers, directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, arising out of or in connection with your use or inability to use the platform, any unauthorized access to or use of our servers, or any content obtained from the platform.</p>
          <p className="content-terms-and-conditions">Indemnification: You agree to indemnify, defend, and hold harmless Meeku LLC, its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys’ fees, arising out of or related to your use of the platform or any violation of these Terms and Conditions.</p>



          {/* 9. GOVERNING LAW:  */}

          <h3 className="underline font-weight-bold-privacy">9. GOVERNING LAW: </h3>


          <p className="content-terms-and-conditions">
            These Terms and Conditions shall be governed by and construed in accordance with the laws of the State of Florida, without regard to its conflict of law principles. Any disputes arising out of or relating to these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts located in Miami, Florida.
          </p>




          {/* 10. CONTACT US:  */}

          <h3 className="underline font-weight-bold-privacy">10. CONTACT US: </h3>
          <p className="content-terms-and-conditions">If you have any questions, concerns, or feedback regarding these Terms and Conditions, please Contact us.</p>

        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
