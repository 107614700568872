import React from "react";
import assets from "../../../../config/assets";
import { Container, Tooltip, } from "@mui/material";
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import "./courses-description-v4.css";
import { useNavigate } from 'react-router-dom';
import storage_configs from '../../../../config/storage-config'
import { getCurrentUserData } from "../../../../utils/util";
import conditional_configs from "../../../../config/conditional_config";
import AuthService from "../../../../services/auth/auth.service";
import PaymentService from "../../../../services/payment-service";
import LoginAsParentPopup from "../../../../components/login-as-parent-popup/login-as-parent-popup";

export default function CoursesDescriptionV4() {

  const [currentSection, setCurrentSection] = useState('features');

  const [selectedDescriptionDetails, setSelectedDescriptionDetails] = useState(null);

  const [subscriptionStatus, setSubscriptionStatus] = useState();

  useEffect(() => {

    if (getCurrentUserData().role === conditional_configs.role.learner) {
      getMySubscriptionDetailByKid();
    } else if (getCurrentUserData().role === conditional_configs.role.parent) {
      getMySubscriptionDetail();
    }


    const storedData = localStorage.getItem(storage_configs.localStorage.sessions.selectedCardDescriptionDetails);

    if (storedData) {
      setSelectedDescriptionDetails(JSON.parse(storedData));
    } else {
      if (getCurrentUserData().role === conditional_configs.role.learner) {
        navigate('/learner/missions');
      } else if (getCurrentUserData().role === conditional_configs.role.parent) {
        navigate('/parent/self-learning');
      }
    }

    
  }, []);

  const getMySubscriptionDetail = () => {
    PaymentService.getMySubscriptionDetail()
      .then((response) => {
        console.log("getMySubscriptionDetail", response);
        if (response && response.data && response.data.length > 0) {
          setSubscriptionStatus(response.data[0].subscription.status);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const getMySubscriptionDetailByKid = () => {
    PaymentService.getMySubscriptionDetailByKid()
      .then((response) => {
        console.log("getMySubscriptionDetail", response);
        if (response && response.data && response.data.length > 0) {
          setSubscriptionStatus(response.data[0].subscription.status);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }


  const navigate = useNavigate();

  const { gameName } = useParams();

  // useEffect(() => {
  //   const sections = ['learningAspects', 'howToPlay'];
  //   let currentIndex = 0;

  //   const intervalId = setInterval(() => {
  //     currentIndex = (currentIndex + 1) % sections.length;
  //     setCurrentSection(sections[currentIndex]);
  //   }, 5000); // Change section every 5 seconds

  //   return () => clearInterval(intervalId);
  // }, []);

  const handleBack = () => {
    // Remove selected whiteboard from localStorage
    localStorage.removeItem(storage_configs.localStorage.sessions.selectedCardDescriptionDetails);

    // Navigate back to the previous page
    navigate(-1);
  };

  const logOut = () => {
    navigate("/learner-login");
    AuthService.logout();
  };

  const navigateTo = (link) => {
    if (getCurrentUserData().role === conditional_configs.role.learner) {
      handleOpenLoginAsParentDialog();
    } else if (getCurrentUserData().role === conditional_configs.role.parent) {
      navigate(link);
    }
  }


  // Dialog login as parent 

  const [openLoginAsParentDialog, setOpenLoginAsParentDialog] = useState(false);


  const handleOpenLoginAsParentDialog = () => {
    setOpenLoginAsParentDialog(true);
  };

  const handleCloseLoginAsParentDialog = () => {
    setOpenLoginAsParentDialog(false);
  };

  const handleConfirmLoginAsParent = () => {
    navigate("/parent-login");
    AuthService.logout();
    handleCloseLoginAsParentDialog();
  };



  return (
    <div className="courses-description-div-v4">
      <Container>
        <div className="d-flex">
          <p className="courses-description-title-v4">{selectedDescriptionDetails?.name}</p>

          <div className="d-flex align-items-center ms-auto">
            {getCurrentUserData()?.role === conditional_configs.role.parent ?
              <Tooltip
                title={"Parents cannot play games or challenges. Viewing as your child will log you out and redirect to the Learner Login page."}
                placement="bottom"
              >
                <button className="login-as-kis-v4" onClick={logOut}>
                  Login as Kid
                </button>
              </Tooltip>
              : ""}

            {subscriptionStatus !== "trialing" && subscriptionStatus !== "active" ? (
              <button
                className="login-as-kis-v4 ms-2"
                onClick={(e) => {
                  e.stopPropagation(); // Prevents Card's onClick from triggering
                  navigateTo("/subscription");
                }}
              >
                Purchase
              </button>
            ) : (
              ""
            )}
          </div>

        </div>

        <div className="courses-video-container-v4 mt-5 mb-4">
          <div className="video-container-div-cv4">
            <iframe
              src={selectedDescriptionDetails?.media_url}
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              width="100%"
              height="400px"
              title="Soccer"
            ></iframe>

          </div>
          <button className="start-game-cgv4 back-btn-des-cgv4" onClick={() => handleBack()}>
            <p className="mb-0 me-2 ">back</p>
            <img className="" width={40} src={assets.homev4.white_with_blue_arrow}></img>
          </button>
        </div>

        {/* Conditionally render description section */}
        {selectedDescriptionDetails?.description && (
          <div className="pt-5">
            <p className="cour-description-text-v4">DESCRIPTION</p>
            <div
              className="cour-des-text-v4"
              dangerouslySetInnerHTML={{ __html: selectedDescriptionDetails?.description }}
            />
          </div>
        )}

        {/* Conditionally render overview section */}
        {selectedDescriptionDetails?.overview && (
          <div className="cour-features-div-v4 mt-5">
            <p className="cour-features-text-v4">Learning Aspects</p>
            <div
              className="cour-features-des-v4"
              dangerouslySetInnerHTML={{ __html: selectedDescriptionDetails?.overview }}
            />
          </div>
        )}

        {selectedDescriptionDetails?.instructions && (
          <div className="cour-features-div-v4 mt-5">
            <p className="cour-features-text-v4">Play</p>
            <div
              className="cour-features-des-v4"
              dangerouslySetInnerHTML={{ __html: selectedDescriptionDetails?.instructions }}
            />
          </div>
        )}
      </Container>

      {/* Dialog login as parent */}



      <LoginAsParentPopup
        openLoginAsParentDialog={openLoginAsParentDialog}
        handleCloseLoginAsParentDialog={handleCloseLoginAsParentDialog}
        handleConfirmLoginAsParent={handleConfirmLoginAsParent}
      />

      {/* Dialog login as parent */}

    </div>
  );
}
